import React from 'react';

import { useWatch } from 'react-hook-form';

import { Link, Stack, Typography } from '@mui/material';

import { FormContext } from '../../../../../../components/pdf/context';
import processor from '../../utils/processor';

const ReverseDir = {
	UP: 'down',
	DOWN: 'up',
	LEFT: 'right',
	RIGHT: 'left',
};

export const Results = (props) => {
	const { baseName, operation, index } = props;
	const { pdfPageData, layoutData, setHighlight } =
		React.useContext(FormContext);

	const operations = useWatch({ name: baseName });

	let { results, breakÍndex, node } = React.useMemo(() => {
		return processor(
			{
				operations: Object.values(operations),
				pageData: pdfPageData,
				pdfData: layoutData,
			},
			{ breakPoint: operation?.uuid },
		);
	}, [operations, operation?.uuid, layoutData, pdfPageData]);

	if (breakÍndex < index) {
		results = '';
		node = null;
	}

	if (operation?.type === 'switch' && breakÍndex === index) {
		results = '';
		node = null;
	}

	return (
		<Stack>
			<Link
				onMouseEnter={() => setHighlight([node?.uuid])}
				onMouseLeave={() => setHighlight([])}
			>
				Nodes:{' '}
				{Array.isArray(node)
					? node.map(({ text }) => text).join(', ')
					: node?.text}
			</Link>
			{typeof results !== 'object' ? (
				<Typography>Results: {results?.toString?.()}</Typography>
			) : (
				<Typography>
					Results: {JSON.stringify(results ?? {}, null, 4)}
				</Typography>
			)}
			{operation?.type === 'move' ? (
				<Typography>
					{`Distance: ${Math.round(
						node?.siblings?.[ReverseDir[operation.dir]]?.distance *
							100,
					)}%`}
				</Typography>
			) : null}
		</Stack>
	);
};
