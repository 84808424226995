import axios from 'axios';

import AsteriaCore from '@asteria/core';

import { AuthService } from '@asteria/backend-utils-services';

import { clearTokens, getStorage, getTokens, setTokens } from './utils';

const instance = axios.create();

const Dataloaders = new Map();

async function refresh(...args) {
	const storage = getStorage();
	const { refreshToken } = getTokens(storage);

	const response = await AuthService.auth
		.refreshToken({ token: refreshToken })
		.then(({ data }) => data)
		.catch(() => false);

	if (!response?.accessToken) {
		clearTokens(storage);
		window.location.pathname = '/';

		return args.map((response) =>
			Promise.reject(response?.data?.errors?.[0]),
		);
	}

	setTokens(storage, response);
	const { accessToken } = getTokens(storage);

	return Promise.all(
		args.map((response) =>
			instance({
				...response?.config,
				headers: {
					...response?.config?.headers,
					Authorization: `Bearer ${accessToken}`,
				},
			}),
		),
	);
}

instance.interceptors.response.use(
	async (response) => {
		const url = response?.config?.url;
		const data = response?.data?.data;
		const errors = response?.data?.errors;

		const code = errors?.[0]?.extensions?.code;
		if (errors?.length) {
			if (code === 'UNAUTHENTICATED') {
				let dataloader = Dataloaders.get(url);

				const storage = getStorage();
				const { refreshToken } = getTokens(storage);

				if (!refreshToken) {
					throw errors[0];
				}

				if (!dataloader) {
					dataloader = new AsteriaCore.DataLoader(refresh, {
						period: 1_000,
					});

					Dataloaders.set(url, dataloader);
				}

				return dataloader
					.execute(response, { waiting: true })
					.then((data) => {
						Dataloaders.clear();
						return data;
					});
			}

			throw errors[0];
		}

		return data;
	},
	(error) => Promise.reject(error),
);

AsteriaCore.HTTP.graphql = async function (options) {
	const { uri, query, variables, headers, signal } = options;

	if (!headers?.skipSessionToken) {
		const accessToken = localStorage.getItem('accessToken');

		if ((headers?.Authorization || headers?.authorization) && accessToken) {
			delete headers?.Authorization;
			delete headers?.authorization;

			headers.Authorization = `Bearer ${accessToken}`;
		}
	}

	delete headers?.skipSessionToken;

	return instance({
		url: uri,
		method: 'POST',
		data: { query: query, variables: variables },
		headers: headers,
		signal: signal,
	});
};

export default AsteriaCore.HTTP;
