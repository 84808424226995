import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import QRCode from 'qrcode';

import { AuthService } from '@asteria/backend-utils-services';

import { selectToken } from '../../store/auth';

const RegisterTwoFactor = () => {
	const navigate = useNavigate();
	const [registerCode, setRegisterCode] = useState(null);
	const accessToken = useSelector(selectToken);

	const {
		register,
		handleSubmit,
		setError,
		clearErrors,
		formState: { errors },
	} = useForm();

	useEffect(() => {
		AuthService.auth.extension
			.registerTwoAuth({}, { token: accessToken })
			.then((data) => {
				return QRCode.toDataURL(data);
			})
			.then((data) => setRegisterCode(data));
	}, [accessToken]);

	const onSubmit = (data) => {
		AuthService.auth.extension
			.validateTwoAuth(data, { token: accessToken })
			.then((data) => {
				if (data) {
					navigate('/');
				} else {
					setError('global', {
						type: 'manual',
						message: 'Incorrect code',
					});
				}
			})
			.catch((err) => {
				let message = err.message;

				if (err.response) {
					message = err.response.data.errors[0].message;
				}

				setError('global', { type: 'manual', message: message });
			});
	};

	return (
		<div className="flex flex-col h-screen items-center justify-center">
			<h1>Register Two Factor</h1>
			<img src={registerCode} alt="qrcode" />
			<div>{errors.global ? errors.global.message : null}</div>
			<form
				className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
				onSubmit={handleSubmit(onSubmit)}
			>
				<div className="mb-4">
					<label className="block text-gray-700 text-sm font-bold mb-2">
						Verification Code
					</label>
					<input
						className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
						type="text"
						name="code"
						{...register('code', { required: true })}
					/>
				</div>
				<button
					className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
					onClick={() => {
						clearErrors('global');
					}}
				>
					Verify
				</button>
			</form>
		</div>
	);
};

export default RegisterTwoFactor;
