import React, { useEffect } from 'react';

import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import {
	Abc,
	AddShoppingCart,
	Brush,
	Edit,
	ExpandMore,
	Refresh,
	Settings,
	Summarize,
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	FormControl,
	IconButton,
	InputLabel,
	ListItemIcon,
	Menu,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from '@mui/material';

import { FormContext } from '../../../../../../components/pdf/context';
import processor from '../../utils/processor';

const adjustComma = (obj, field) => {
	let fieldData = (obj[field] ?? '').trim();
	console.log(fieldData, fieldData.endsWith(','));
	if (fieldData && fieldData.endsWith(',')) {
		fieldData = fieldData.substring(0, fieldData.length - 1);
		fieldData = fieldData.trim();
		const lastIndexOfSpace = fieldData.lastIndexOf(' ');
		fieldData =
			fieldData.substring(0, lastIndexOfSpace) +
			',' +
			fieldData.substring(lastIndexOfSpace + 1);

		obj[field] = fieldData;
	} else if (fieldData && fieldData.startsWith(',')) {
		fieldData = fieldData.substring(0, fieldData.length);
		fieldData = fieldData.trim();
		const lastIndexOfSpace = fieldData.lastIndexOf(' ');
		fieldData =
			fieldData.substring(1, lastIndexOfSpace).trim() +
			',' +
			fieldData.substring(lastIndexOfSpace + 1).trim();

		obj[field] = fieldData;
	}
};

export const RowsConfig = (props) => {
	const { setSelectedField } = props;
	const { pdfPageData, layoutData } = React.useContext(FormContext);

	const handleClick = React.useCallback(
		(event) => {
			setSelectedField('rows');
		},
		[setSelectedField],
	);

	const { setValue, setError, register, watch } = useFormContext();
	const values = useWatch({ name: 'rows' });
	const operations = useWatch({ name: `layout.operations.rows` });
	const invoiceColumns = useWatch({ name: `invoice.columns` });
	const columnConfig = useWatch({ name: `layout.configuration.columns` });

	const rowConfig = useWatch({
		name: 'layout.configuration.rows',
	});

	register('rows');

	useEffect(() => {
		if (!operations) {
			return;
		}

		const { results } = processor({
			operations: Object.values(operations),
			pageData: pdfPageData,
			pdfData: layoutData,
		});

		if (results === null) {
			setValue('rows', []);
			setError('rows');
		} else {
			const rows = [];

			let summaryRegex = null;
			let summaryText = 'Ordersumma';

			if (rowConfig?.summary?.selector) {
				try {
					summaryRegex = new RegExp(
						rowConfig?.summary?.selector,
						'gmi',
					);
				} catch (e) {
					// DO nothing
				}
			}

			if (rowConfig?.summary?.label) {
				summaryText = rowConfig?.summary?.label;
			}

			/*
			let activeColumnKeys = [];
			for (let i = 0; i < results.length; i += 1) {
				const rowData = results[i];

				let row = {};
				if (rowData.type === 'table') {
					activeColumnKeys = Object.values(columnConfig ?? {}).filter(
						({ label }) => rowData.data.includes(label),
					);

					continue;
				} else if (rowData.type === 'text') {
					row = {
						type: 'text',
						description: rowData.data,
					};
				} else {
					const requiredFields = Object.values(columnConfig ?? {})
						.filter(({ required }) => required)
						.map(({ key }) => key)
						.filter((key) => activeColumnKeys.includes(key));

					row = Object.values(invoiceColumns ?? {}).reduce(
						(acc, { selector, key, label }) => {
							acc[key] = rowData?.data?.[label.trim()] ?? '';

							return acc;
						},
						{},
					);

					row.type = 'product';

					const entires = Object.entries(row)
						.filter(([key, val]) => val)
						.map(([key]) => key);

					adjustComma(row, 'price');
					adjustComma(row, 'total');

					if (
						!requiredFields.every((field) =>
							entires.includes(field),
						)
					) {
						row = {
							description: Object.values(row)
								.filter((item) => item)
								.join(' '),
						};
					}

					row.extra = rowData.extra;
				}

				if (summaryRegex) {
					summaryRegex.lastIndex = 0;
				}

				if (
					summaryRegex &&
					summaryRegex.test(row.productId || row.description)
				) {
					summaryRegex.lastIndex = 0;
					const matchResults = summaryRegex.exec(
						row.productId || row.description,
					);
					row.type = 'order-summary';
					row.description =
						matchResults?.groups?.description ?? summaryText;
					row.total = matchResults?.groups?.total ?? row.total;
					adjustComma(row, 'total');
				}

				if (
					row.total &&
					!row.productId &&
					!row.price &&
					!row.description &&
					!row.qty
				) {
					row.type = 'order-summary';
					row.description = summaryText;
				}

				rows.push(row);
			}
			*/

			const activeColumnKeys = Object.values(invoiceColumns ?? {}).map(
				({ key }) => key,
			);
			const requiredFields = Object.values(columnConfig ?? {})
				.filter(({ required }) => required)
				.map(({ key }) => key)
				.filter((key) => activeColumnKeys.includes(key));

			if (rowConfig?.summary?.label) {
				summaryText = rowConfig?.summary?.label;
			}

			for (let i = 0; i < results.length; i += 1) {
				const rowData = results[i];
				let row = Object.values(invoiceColumns ?? {}).reduce(
					(acc, { selector, key, label }) => {
						acc[key] = rowData[label.trim()] ?? '';

						return acc;
					},
					{},
				);

				const entires = Object.entries(row)
					.filter(([key, val]) => val)
					.map(([key]) => key);

				adjustComma(row, 'qty');
				adjustComma(row, 'price');
				adjustComma(row, 'total');

				if (!requiredFields.every((field) => entires.includes(field))) {
					row = {
						description: Object.values(row)
							.filter((item) => item)
							.join(' '),
					};
				}

				row.extra = rowData.extra;

				if (summaryRegex) {
					summaryRegex.lastIndex = 0;
				}

				if (
					summaryRegex &&
					summaryRegex.test(row.productId || row.description)
				) {
					summaryRegex.lastIndex = 0;
					const matchResults = summaryRegex.exec(
						row.productId || row.description,
					);
					row.type = 'order-summary';
					row.description =
						matchResults?.groups?.description ?? summaryText;
					row.total = matchResults?.groups?.total ?? row.total;
					adjustComma(row, 'total');
				}

				if (
					row.total &&
					!row.productId &&
					!row.price &&
					!row.description &&
					!row.qty
				) {
					row.type = 'order-summary';
					row.description = summaryText;
				}

				rows.push(row);
			}

			setValue('rows', rows);
		}
	}, [
		operations,
		pdfPageData,
		layoutData,
		setValue,
		setError,
		invoiceColumns,
		rowConfig,
		columnConfig,
	]);

	return (
		<Stack direction="column">
			<IconButton aria-label="menu" onClick={handleClick}>
				<Edit />
			</IconButton>
			<TextField
				label="Summary Row Selector"
				variant="outlined"
				{...register(`layout.configuration.rows.summary.selector`)}
			/>
			<TextField
				label="Summary Row Label"
				variant="outlined"
				{...register(`layout.configuration.rows.summary.label`)}
			/>
			{Object.values(values ?? {}).map((row, index) => (
				<Accordion key={row.id ?? index}>
					<AccordionSummary expandIcon={<ExpandMore />}>
						{row.type === 'text' ? <Abc /> : null}
						{row.type === 'product' ? <AddShoppingCart /> : null}
						{row.type === 'order-summary' ? <Summarize /> : null}
						{row.type === 'text' ? (
							<Typography variant="body2">{`${row.description}`}</Typography>
						) : null}
						{row.type === 'product' ? (
							<Typography fontWeight="bold">{`${
								row.productId ?? row.description
							}`}</Typography>
						) : null}
						{row.type !== 'text' && row.type !== 'product' ? (
							<Stack direction="column">
								<Typography>{`(${index}) - ${
									row.productId ?? 'Text Row'
								}`}</Typography>
								<Typography variant="body2">
									{row.description}
								</Typography>
							</Stack>
						) : null}
						{row.type === 'order-summary' ? (
							<Typography>{`${index} - Summary: ${row.total}`}</Typography>
						) : null}
					</AccordionSummary>
					<AccordionDetails>
						{Object.entries(row)
							.filter(
								([key]) => key !== 'extra' && key !== 'type',
							)
							.map(([key, value]) => (
								<TextField
									key={key}
									label={key}
									variant="outlined"
									value={value}
									{...register(`rows.${index}.${key}`)}
								/>
							))}
						{(row.extra ?? []).map((value, index) => (
							<Typography key={`extra-${index}`}>
								{value}
							</Typography>
						))}
					</AccordionDetails>
				</Accordion>
			))}
		</Stack>
	);
};
