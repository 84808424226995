import React, { useCallback, useMemo, useRef } from 'react';

import {
	MdFlag,
	MdGroup,
	MdHome,
	MdMediation,
	MdTimeline,
} from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BarChartIcon from '@mui/icons-material/BarChart';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DescriptionIcon from '@mui/icons-material/Description';
import FlagIcon from '@mui/icons-material/Flag';
import LogoutIcon from '@mui/icons-material/Logout';
import MediationIcon from '@mui/icons-material/Mediation';
import MessageIcon from '@mui/icons-material/Message';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import TimelineIcon from '@mui/icons-material/Timeline';
import {
	Divider,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
} from '@mui/material';

import { AuthService } from '@asteria/backend-utils-services';

import * as Context from '../../context';
import * as Ducks from '../../ducks';
import { logout as logoutUser } from '../../store/auth';

import NavItem from './item';

/*
<div className="flex flex-row">
	<div className="flex flex-col w-20 min-w-[5rem] max-w-[5rem] py-4 h-screen items-center bg-red-700 justify-center sticky top-0 left-0">
		<Logo
			click={home}
			className="mb-auto fill-current text-white"
		/>
		<Navigation />

		<Dropdown
			iconDropdownOpen={
				<MdAccountCircle className="h-6 w-6 text-gray-100" />
			}
			iconDropdownClosed={
				<MdAccountCircle className="h-6 w-6 text-gray-100/50 hover:text-gray-100" />
			}
			dropdownOptions={dropdownOptions}
			className="mt-auto"
		/>
	</div>
	<BreadcrumbsNavigation />
	<div className="container ml-auto mr-auto">
		<div className="flex-column flex-grow mx-2">

		</div>
	</div>
</div>
*/

const Navigation = ({ className }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const { state: { accessToken } = {}, dispatch } = React.useContext(
		Context.auth.Context,
	);

	const { dispatch: dispatchPartner } = React.useContext(
		Context.partner.Context,
	);

	const reduxDispatch = useDispatch();

	const navItems = useMemo(
		() =>
			[
				{
					name: 'overview',
					title: 'Overview',
					icon: <DashboardIcon />,
					path: '/',
				},
				// {
				// 	name: 'usageAnalytics',
				// 	title: 'Analytics',
				// 	icon: <TimelineIcon />,
				// 	path: '/usageAnalytics',
				// },
				// {
				// 	name: 'partners',
				// 	title: 'Partners',
				// 	icon: <PeopleIcon />,
				// 	path: '/partners',
				// },
				// {
				// 	name: 'companies',
				// 	title: 'Companies',
				// 	icon: <PeopleIcon />,
				// 	path: '/companies',
				// },
				{
					name: 'translations',
					title: 'Translations',
					icon: <FlagIcon />,
					path: '/translations',
				},
				{
					name: 'features',
					title: 'Features',
					icon: <MediationIcon />,
					path: '/features',
				},
				{
					name: 'invoices',
					title: 'Invoices',
					icon: <DescriptionIcon />,
					path: '/invoices',
				},
				{
					name: 'chats',
					title: 'Chats',
					icon: <MessageIcon />,
					path: '/chats',
				},
				{
					name: 'queue',
					title: 'Queue',
					icon: <EngineeringIcon />,
					path: '/queue',
				},
			].map((object, index) => ({ ...object, id: index })),
		[],
	);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = useCallback((event) => {
		setAnchorEl(event.currentTarget);
	}, []);
	const handleClose = useCallback(() => {
		setAnchorEl(null);
	}, []);

	const logout = React.useCallback(() => {
		Promise.allSettled([
			AuthService.auth.logout({}, { token: accessToken }),
			dispatch(Ducks.auth.logout()),
			reduxDispatch(logoutUser()),
		]).then(() => {
			navigate('/');
		});
	}, []);

	const settings = React.useCallback(() => {
		navigate('/settings');
		handleClose();
	}, [navigate, handleClose]);

	return (
		<List component="nav">
			{navItems.map((item) => (
				<NavItem
					key={item.id}
					active={location.pathname === item.path}
					item={item}
				/>
			))}
			<Divider sx={{ my: 1 }} />
			<ListItemButton
				ref={anchorEl}
				onClick={handleClick}
				sx={{ marginTop: 'auto' }}
			>
				<ListItemIcon>
					<AccountCircleIcon />
				</ListItemIcon>
				<ListItemText primary="Options" />
			</ListItemButton>

			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				id="account-menu"
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				<MenuItem onClick={settings}>
					<ListItemIcon>
						<SettingsIcon fontSize="small" />
					</ListItemIcon>
					Settings
				</MenuItem>
				<MenuItem onClick={logout}>
					<ListItemIcon>
						<LogoutIcon fontSize="small" />
					</ListItemIcon>
					Logout
				</MenuItem>
			</Menu>
		</List>
	);
};

export default Navigation;
