import { formatISO, parse } from 'date-fns';
import sv from 'date-fns/locale/sv';

const Locales = {
	sv: sv,
};

const formatDate = ({ op, node, results }) => {
	if (!results) {
		return [results, node];
	}

	const matchingFormat = op.formats.find(({ matcher }) =>
		new RegExp(matcher).test(results),
	);

	if (!matchingFormat) {
		return [null, node];
	}

	let date = null;

	if (matchingFormat.locale) {
		date = parse(results.trim(), matchingFormat.format, new Date(), {
			locale: Locales[matchingFormat.locale],
		});
	} else {
		date = parse(results.trim(), matchingFormat.format, new Date());
	}

	if (date instanceof Date && !isNaN(date)) {
		return [formatISO(date, { representation: 'date' }), node];
	}

	return [null, node];
};

export default formatDate;
