import * as React from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import * as Context from '../context';
import * as Ducks from '../ducks';
import Login from '../pages/Login';

const NonAuthenticatedLayout = () => {
	const { dispatch } = React.useContext(Context.auth.Context);

	React.useEffect(() => {
		dispatch(Ducks.auth.logout());
	}, [dispatch]);

	return (
		<Routes>
			<Route path="/" element={<Login />} exact />
			<Route path="*" element={<Navigate to="/" />} />
		</Routes>
	);
};

export default NonAuthenticatedLayout;
