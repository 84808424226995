/* Constants */

export const ALLOW = 'ALLOW';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

/* Reducer */

export const initialState = {
	isLoggedIn: null,
	accessToken: localStorage.getItem('accessToken'),
	refreshToken: localStorage.getItem('refreshToken'),
	accessTokenExpires: localStorage.getItem('accessTokenExpires'),
	refreshTokenExpires: localStorage.getItem('refreshTokenExpires'),
};

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN: {
			const {
				accessToken,
				refreshToken,
				accessTokenExpires,
				refreshTokenExpires,
			} = action.payload;

			localStorage.setItem('accessToken', accessToken);
			localStorage.setItem('refreshToken', refreshToken);
			localStorage.setItem('accessTokenExpires', accessTokenExpires);
			localStorage.setItem('refreshTokenExpires', refreshTokenExpires);

			return {
				...state,
				accessToken: accessToken,
				refreshToken: refreshToken,
				accessTokenExpires: accessTokenExpires,
				refreshTokenExpires: refreshTokenExpires,
				isLoggedIn: true,
			};
		}

		case ALLOW:
			return { ...state, isLoggedIn: true };

		case LOGOUT:
			localStorage.removeItem('accessToken');
			localStorage.removeItem('refreshToken');
			localStorage.removeItem('accessTokenExpires');
			localStorage.removeItem('refreshTokenExpires');

			return { ...initialState, isLoggedIn: false };

		default:
			return state;
	}
};

/* Actions */

export const allow = () => ({ type: ALLOW });
export const login = (data) => ({ type: LOGIN, payload: data });
export const logout = () => ({ type: LOGOUT });
