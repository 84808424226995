import React from 'react';

import { useSelector } from 'react-redux';

import { Info } from '@mui/icons-material';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { formatISO } from 'date-fns';

import {
	CompanyService,
	PartnerService,
} from '@asteria/backend-utils-services';

import PartnerSelector from '../../components/partner/selector';
import * as Hooks from '../../hooks';
import { selectToken } from '../../store/auth';

import CompanyDetailsModal from './CompanyDetailsModal';

const PartnerLayer = (props) => {
	const { children } = props;
	const accessToken = useSelector(selectToken);

	const partnerRequestOptions = React.useMemo(() => {
		return {
			pageFilters: { first: 0 },
			fields: 'edges { node { _id name settings { themeId } } }',
		};
	}, []);

	const { data } = Hooks.request.useRequest({
		handler: PartnerService.partner.fetch,
		options: partnerRequestOptions,
		context: { token: accessToken },
	});

	return children({
		data: (data?.edges ?? []).map(({ node }) => node),
	});
};

const CompanyTable = (props) => {
	const accessToken = useSelector(selectToken);
	const [partnerId, setPartnerId] = React.useState(null);

	const [
		{ open: isDetailsOpen, state: detailsState },
		{ onOpen: onDetailsOpen, onClose: onDetailsClose },
	] = Hooks.modals.useModalState();

	const { data: companies, isLoading } = useQuery({
		queryKey: ['companies', 'list', partnerId],
		queryFn: () =>
			CompanyService.company
				.fetch(
					{
						pageFilters: { first: 0 },
						filters: {
							deleted: false,
							partnerId: partnerId,
						},
						fields: 'edges { node { _id name orgnumber createdAt } }',
					},
					{ token: accessToken },
				)
				.then((response) =>
					(response?.edges ?? []).map(({ node }) => node),
				),
	});

	return (
		<>
			<CompanyDetailsModal
				open={isDetailsOpen}
				onClose={onDetailsClose}
				{...detailsState}
			/>
			<PartnerLayer>
				{({ data: partners }) => (
					<>
						<PartnerSelector
							partners={partners}
							onChange={(e, item) => {
								if (item) {
									setPartnerId(item._id);
								} else {
									setPartnerId(null);
								}
							}}
						/>
						<DataGrid
							disableRowSelectionOnClick
							columns={[
								{ field: '_id', headerName: 'ID', flex: 1 },
								{ field: 'name', headerName: 'Name', flex: 1 },
								{
									field: 'orgnumber',
									headerName: 'Org Number',
									flex: 1,
								},
								{
									field: 'createdAt',
									headerName: 'Created',
									flex: 1,
									type: 'date',
									valueGetter: ({ value }) =>
										value && new Date(value),
									valueFormatter: ({ value }) =>
										value &&
										formatISO(value, {
											representation: 'date',
										}),
								},
								{
									field: 'actions',
									type: 'actions',
									width: 40,
									getActions: (params) => [
										<GridActionsCellItem
											label="Details"
											onClick={() =>
												onDetailsOpen({ id: params.id })
											}
											icon={<Info />}
										/>,
									],
								},
							]}
							rows={companies ?? []}
							loading={isLoading}
							getRowId={({ _id }) => _id}
							slots={{ toolbar: GridToolbar }}
							slotProps={{
								toolbar: {
									showQuickFilter: true,
								},
							}}
						/>
					</>
				)}
			</PartnerLayer>
		</>
	);
};

export default CompanyTable;
