import React from 'react';

import { Info } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
	Box,
	Dialog,
	DialogContent,
	Grid,
	IconButton,
	Modal,
	Paper,
	Stack,
	Typography,
} from '@mui/material';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import JsonView from '@uiw/react-json-view';
import { formatDuration, intervalToDuration } from 'date-fns';

import { AdminService } from '@asteria/backend-utils-services';

const modalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '75%',
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	pt: 2,
	px: 4,
	pb: 3,
};

function getRowId(row) {
	return row.uuid;
}

const QueuePage = (props) => {
	const [job, setJob] = React.useState(null);
	const handleClose = () => setJob(null);
	const { data: queueJobs = [], isLoading, isFetching } = useQuery({
		queryKey: ['queue', 'items'],
		queryFn: () => AdminService.theme.extension.queueJobs(),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
	});

	const queryClient = useQueryClient();
	const fetchJobs = React.useCallback(
		async (reset = true) => {
			queryClient.invalidateQueries('layouts');
		},
		[queryClient],
	);

	const integrations = React.useMemo(() => {
		return Object.values(
			queueJobs.reduce((acc, item) => {
				if (!acc[item?.data?.scope?.integrationId]) {
					acc[item?.data?.scope?.integrationId] = {
						integrationId: item?.data?.scope?.integrationId,
						jobs: [],
					};
				}

				acc[item?.data?.scope?.integrationId].jobs.push(item);

				return acc;
			}, {}),
		);
	}, [queueJobs]);

	return (
		<Stack
			direction="column"
			spacing={2}
			sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
		>
			<Grid container spacing={2}>
				<Grid item xs>
					<Paper sx={{ p: 2, textAlign: 'center' }}>
						<Typography gutterBottom>Jobs</Typography>
						<Typography variant="h5">{queueJobs.length}</Typography>
					</Paper>
				</Grid>

				<Grid item xs>
					<Paper sx={{ p: 2, textAlign: 'center' }}>
						<Typography gutterBottom>Integrations</Typography>
						<Typography variant="h5">
							{integrations.length}
						</Typography>
					</Paper>
				</Grid>
			</Grid>

			<Paper sx={{ p: 2, flex: 1 }}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						gap: 2,
					}}
				>
					<IconButton onClick={fetchJobs}>
						<RefreshIcon />
					</IconButton>
				</Box>
				<Dialog open={!!job} onClose={handleClose}>
					<DialogContent>
						<JsonView value={job ?? {}} />
					</DialogContent>
				</Dialog>
				<DataGrid
					disableRowSelectionOnClick
					getRowId={getRowId}
					density="compact"
					columns={[
						{
							field: 'data.scope.integrationId',
							valueGetter: ({ row }) => {
								return row?.data?.scope?.integrationId;
							},
							headerName: 'integrationId',
							flex: 1,
						},
						{
							field: 'type',
							headerName: 'Queue',
							flex: 1,
						},
						{
							field: 'status',
							headerName: 'Status',
							flex: 1,
						},
						{
							field: 'channel',
							headerName: 'Channel',
							flex: 1,
						},
						{
							field: 'data.attempt',
							valueGetter: ({ row }) => {
								return row?.data?.payload?.attempt ?? 0;
							},
							headerName: 'Attempts',
							flex: 1,
						},
						{
							field: 'running',
							headerName: 'Running time',
							valueGetter: ({ row }) => {
								return formatDuration(
									intervalToDuration({
										start: new Date(row.startedAt),
										end: new Date(),
									}),
									{ format: ['minutes', 'seconds'] },
								);
							},
							flex: 1,
						},
						{
							field: 'actions',
							type: 'actions',
							width: 40,
							getActions: (params) => [
								<GridActionsCellItem
									label="Details"
									onClick={() => setJob(params.row)}
									icon={<Info />}
								/>,
							],
						},
					]}
					rows={queueJobs ?? []}
					loading={isLoading || isFetching}
					slots={{ toolbar: GridToolbar }}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
						},
					}}
				/>
			</Paper>
		</Stack>
	);
};

export default QueuePage;
