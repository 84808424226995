import React from 'react';

import { useFormContext } from 'react-hook-form';

import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Stack,
} from '@mui/material';

export const Convert = (props) => {
	const { name, operation } = props;
	const { register } = useFormContext();

	return (
		<Stack>
			<FormControl fullWidth>
				<InputLabel id="demo-simple-select-label">Type</InputLabel>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={operation.toType ?? ''}
					label="Type"
					{...register(`${name}.toType`)}
				>
					<MenuItem value="">No Conversion</MenuItem>
					<MenuItem value="string">String</MenuItem>
					<MenuItem value="number">Number</MenuItem>
					<MenuItem value="float">Deciaml Number</MenuItem>
					<MenuItem value="date">Date</MenuItem>
				</Select>
			</FormControl>
		</Stack>
	);
};
