export function getStorage() {
	return window.localStorage;
}

/**
 *
 * @param { Storage } storage
 */
export function getTokens(storage) {
	return {
		accessToken: storage.getItem('accessToken'),
		refreshToken: storage.getItem('refreshToken'),
	};
}

/**
 * @param { Storage } storage
 * @param { { accessToken: string, refreshToken: string } } data
 */
export function setTokens(storage, data) {
	storage.setItem('accessToken', data?.accessToken);
	storage.setItem('refreshToken', data?.refreshToken);
}

/**
 * @param { Storage } storage
 */
export function clearTokens(storage) {
	storage.removeItem('accessToken');
	storage.removeItem('refreshToken');
}
