import { format, formatISO, parse } from 'date-fns';

const convert = ({ op, node, results }) => {
	if (!results) {
		return [results, node];
	}

	let converted = results;

	if (op.toType === 'string') {
		converted = results.toString();
	} else if (op.toType === 'number') {
		converted = parseInt(results);
	} else if (op.toType === 'float') {
		converted = parseFloat(results);
	} else if (op.toType === 'date') {
		converted = format(new Date(results.toString().trim()), 'yyyy-MM-dd');
	}

	return [converted, node];
};

export default convert;
