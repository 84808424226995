import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';

import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import HistoryIcon from '@mui/icons-material/History';
import SaveIcon from '@mui/icons-material/Save';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import {
	IconButton,
	InputAdornment,
	LinearProgress,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Paper,
	Popper,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {
	DataGrid,
	GridActionsCellItem,
	GridToolbarContainer,
	useGridApiContext,
	useGridApiRef,
} from '@mui/x-data-grid';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import { GridColumnMenu } from '@mui/x-data-grid';
import { GridToolbarExport } from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';
import { isEqual } from 'lodash';
import { useSnackbar } from 'notistack';

import {
	LanguageService,
	PartnerService,
} from '@asteria/backend-utils-services';

import Error from '../../components/form/Error';
import PartnerSelector from '../../components/partner/selector';
import { Context as TranslationContext } from '../../context/translations';
import * as Hooks from '../../hooks';
import { selectToken } from '../../store/auth';
import { group } from '../../utils/dataUtils';

import TranslationsSync from './sync';

function jsonToString(value) {
	if (!value) {
		return value;
	}

	if (typeof value === 'string') {
		return value;
	}

	return JSON.stringify(value);
}

function stringToJson(value) {
	if ((value ?? null) === null) {
		return value;
	}

	if (typeof value === 'string') {
		try {
			const input = JSON.parse(value);

			if (typeof input === 'object') {
				return input;
			}
		} catch (err) {
			//
		}
	}

	return value;
}

const PartnerLayer = (props) => {
	const { children } = props;
	const accessToken = useSelector(selectToken);

	const partnerRequestOptions = useMemo(() => {
		return {
			pageFilters: { first: 0 },
			fields: 'edges { node { _id name settings { themeId } } }',
		};
	}, []);

	const { data, error } = Hooks.request.useRequest({
		handler: PartnerService.partner.fetch,
		options: partnerRequestOptions,
		context: { token: accessToken },
	});

	if (error) {
		return <Error error={error} />;
	}

	return children({
		data: (data?.edges ?? []).map(({ node }) => node),
	});
};

const TranslationLayer = (props) => {
	const { children } = props;

	const accessToken = useSelector(selectToken);
	const { state = {} } = useContext(TranslationContext);

	const [settings, setSettings] = useState(state.settings);
	const [shouldFetch, setShouldFetch] = useState(true);

	useEffect(() => {
		setSettings(state.settings);
	}, [state.settings]);

	const reFetch = useCallback(() => {
		setShouldFetch((value) => !value);
	}, []);

	const refetchOptions = useMemo(() => {
		return {};
	}, [shouldFetch]);

	const requestOptions = useMemo(() => {
		return {
			themeId: settings.themeId,
			partnerId: settings.partnerId,
			...refetchOptions,
		};
	}, [settings.themeId, settings.partnerId, refetchOptions]);

	const { data } = Hooks.request.useRequest({
		handler: LanguageService.translation.extension.fetch,
		options: refetchOptions,
		context: { token: accessToken },
	});

	const { data: partnerData } = Hooks.request.useRequest({
		handler: LanguageService.translation.extension.fetch,
		options: requestOptions,
		context: { token: accessToken },
	});

	const { data: keys } = Hooks.request.useRequest({
		handler: LanguageService.translation.extension.keys,
		options: refetchOptions,
		context: { token: accessToken },
	});

	const groupedData = useMemo(() => {
		if (!data) {
			return {};
		}

		return group(data, 'code');
	}, [data]);

	const groupedPartnerData = useMemo(() => {
		if (!partnerData) {
			return {};
		}

		return group(partnerData, 'code');
	}, [partnerData]);

	return children({
		data: groupedPartnerData,
		baseData: groupedData,
		keys: keys,
		settings: settings,
		setSettings: setSettings,
		reFetch: reFetch,
	});
};

function EditToolbar(props) {
	const { setRows, selected, partners, setSettings, settings } = props;

	const context = useGridApiContext();

	const handleAdd = () => {
		const id = randomId();

		const key =
			context?.current?.exportState()?.filter?.filterModel
				?.quickFilterValues?.[0] ?? '';

		setRows((oldRows) => [...oldRows, { id, key: key, isNew: true }]);
	};

	const handleRemove = () => {
		setRows((oldRows) =>
			oldRows.filter(({ id }) => !selected.includes(id)),
		);
	};

	return (
		<GridToolbarContainer>
			{selected && selected.length > 0 && (
				<Button
					color="error"
					startIcon={<DeleteIcon />}
					onClick={handleRemove}
				>
					Delete
				</Button>
			)}
			<GridToolbarQuickFilter />
			<Button color="primary" startIcon={<AddIcon />} onClick={handleAdd}>
				Add record
			</Button>
			<GridToolbarExport />
			<PartnerSelector
				sx={{
					ml: 'auto',
					width: '200px',
				}}
				partners={partners}
				onChange={(e, item) => {
					if (item) {
						setSettings({ ...settings, partnerId: item._id });
					} else {
						setSettings({ ...settings, partnerId: null });
					}
				}}
			/>
		</GridToolbarContainer>
	);
}

function equals(obj1, obj2) {
	return Object.keys(obj1)
		.concat(Object.keys(obj2))
		.every((key) => {
			return obj1[key] === obj2[key];
		});
}

const EditTextarea = (props) => {
	const { id, field, value, colDef } = props;

	const [valueState, setValueState] = React.useState(value);
	const [renderState, setRenderState] = React.useState(jsonToString(value));

	const [anchorEl, setAnchorEl] = React.useState();
	const apiRef = useGridApiContext();

	const handleRef = React.useCallback((el) => {
		setAnchorEl(el);
	}, []);

	const handleChange = React.useCallback(
		(event, value) => {
			const newValue = value === undefined ? event.target.value : value;
			setValueState(newValue);
			setRenderState(newValue === null ? '' : newValue);
			apiRef.current.setEditCellValue(
				{ id, field, value: newValue, debounceMs: 200 },
				event,
			);
		},
		[apiRef, field, id],
	);

	const handleKeyDown = React.useCallback(
		(event) => {
			if (
				event.key === 'Escape' ||
				(event.key === 'Enter' &&
					!event.shiftKey &&
					(event.ctrlKey || event.metaKey))
			) {
				const params = apiRef.current.getCellParams(id, field);
				apiRef.current.publishEvent('cellKeyDown', params, event);
			}

			event.stopPropagation();
		},
		[apiRef, id, field],
	);

	const caption = props?.row?.base?.[props.field];

	return (
		<div style={{ position: 'relative', alignSelf: 'flex-start' }}>
			<div
				ref={handleRef}
				style={{
					height: 1,
					width: colDef.computedWidth,
					display: 'block',
					position: 'absolute',
					top: 0,
				}}
			/>
			{anchorEl && (
				<Popper open anchorEl={anchorEl} placement="bottom-start">
					<Paper
						elevation={1}
						sx={{ p: 1, minWidth: colDef.computedWidth }}
					>
						<TextField
							multiline
							variant="standard"
							value={renderState}
							fullWidth
							onChange={handleChange}
							autoFocus
							onKeyDown={handleKeyDown}
							placeholder={
								valueState === null ? 'No translation' : ''
							}
							InputProps={{
								endAdornment: valueState !== null && (
									<InputAdornment position="end">
										<IconButton
											aria-label="Clear text"
											onClick={(event) =>
												handleChange(event, null)
											}
											edge="end"
										>
											<FormatClearIcon />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
						<Typography variant="caption">
							{jsonToString(caption)}
						</Typography>
					</Paper>
				</Popper>
			)}
		</div>
	);
};

function CustomUserItem(props) {
	const { myCustomHandler, myCustomValue } = props;
	return (
		<MenuItem onClick={myCustomHandler}>
			<ListItemIcon>
				<SettingsApplicationsIcon fontSize="small" />
			</ListItemIcon>
			<ListItemText>{myCustomValue}</ListItemText>
		</MenuItem>
	);
}

function CustomColumnMenu(props) {
	const { onAction, ...restProps } = props;
	return (
		<GridColumnMenu
			{...restProps}
			slots={{
				columnMenuUserItem: CustomUserItem,
			}}
			slotProps={{
				columnMenuUserItem: {
					displayOrder: 15,
					myCustomValue: 'Sync',
					myCustomHandler: () => onAction(restProps.colDef),
				},
			}}
		/>
	);
}

const initialState = {
	sorting: {
		sortModel: [{ field: 'key', sort: 'asc' }],
	},
	columns: {
		columnVisibilityModel: {
			isNew: false,
		},
	},
};

function defaultValueFormatter(params) {
	if ((params.value ?? null) === null) {
		return '<No Translation>';
	}

	return jsonToString(params.value);
}

function defaultRenderCell(params) {
	const base = params?.row?.base?.[params.field];
	const value = params.value;
	const formattedValue = params.formattedValue;

	if (!isEqual(base, value)) {
		return (
			<Tooltip title={jsonToString(base)}>
				<span>{formattedValue}</span>
			</Tooltip>
		);
	}

	return <span>{formattedValue}</span>;
}

function defaultRenderEditCell(params) {
	return <EditTextarea {...params} />;
}

const Translations = (props) => {
	const {
		translations,
		baseTranslations,
		keys,
		setSettings,
		settings,
		partners,
		reFetch,
	} = props;

	const accessToken = useSelector(selectToken);
	const apiRef = useGridApiRef();
	const [selected] = useState(false);
	const [originalRows, setOriginalRows] = React.useState([]);
	const [rows, setRows] = React.useState([]);
	const [rowModesModel, setRowModesModel] = React.useState({});

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	useEffect(() => {
		if (!keys || !translations) {
			return;
		}

		const languages = Object.keys(translations);

		const translationsRows = keys.map((key) => {
			const items = languages.reduce((acc, lang) => {
				acc[lang] = translations?.[lang]?.translations?.[key.key];

				return acc;
			}, {});

			const base = languages.reduce((acc, lang) => {
				acc[lang] = baseTranslations?.[lang]?.translations?.[key.key];

				return acc;
			}, {});

			return { id: key.id, key: key.key, ...items, base: base };
		});

		setOriginalRows(
			translationsRows.reduce((acc, item) => {
				acc[item.id] = item;
				return acc;
			}, {}),
		);
		setRows(translationsRows);
	}, [keys, translations, baseTranslations]);

	const handleDeleteClick = (id) => () => {
		setRows((prevRows) => {
			return prevRows
				.map((row, index) => {
					if (row.id === id && row.isNew) {
						return null;
					}

					return row.id === id
						? { ...row, isDeleted: !row.isDeleted }
						: row;
				})
				.filter((item) => item);
		});
	};

	const handleRevertClick = (rowId) => () => {
		setOriginalRows((originalRows) => {
			const original = originalRows[rowId];
			setRows((prevRows) => {
				return prevRows
					.map((row, index) => {
						if (row.id === rowId && row.isNew) {
							return null;
						}

						return row.id === rowId ? original : row;
					})
					.filter((item) => item);
			});

			return originalRows;
		});
	};

	const handleUpdate = useCallback((newRow, oldRow) => {
		setRows((prevRows) => {
			return prevRows
				.map((row, index) => {
					if (row.id === oldRow.id) {
						return {
							...newRow,
							isEdited:
								!oldRow.isNew &&
								(oldRow.isEdited || !equals(newRow, oldRow)),
						};
					}

					return row;
				})
				.filter((item) => item);
		});
		return {
			...newRow,
			isEdited: oldRow.isEdited || !equals(newRow, oldRow),
		};
	}, []);

	const handleSave = useCallback(async () => {
		const newRows = rows.filter(({ isNew }) => isNew);
		const removedRows = rows.filter(({ isDeleted }) => isDeleted);
		const editedRows = rows.filter(({ isEdited }) => isEdited);
		console.log(newRows);
		//TODO: Only do for changed languages!
		let translationsUpdate = editedRows
			.flatMap(({ id, key, isEdited, base, ...rest }) =>
				Object.keys(rest).map((lang) => ({
					key: key,
					language: lang,
					partnerId: settings.partnerId,
					diff: originalRows[id][lang] !== rest[lang],
					content:
						originalRows[id][lang] !== rest[lang]
							? rest[lang]
							: null,
				})),
			)
			.filter(({ diff }) => diff);

		translationsUpdate = translationsUpdate.concat(
			newRows.flatMap(({ id, key, isNew, isEdited, base, ...rest }) =>
				Object.keys(rest).map((lang) => ({
					key: key,
					language: lang,
					content: rest[lang] || null,
					partnerId: settings.partnerId,
				})),
			),
		);

		if (translationsUpdate.length > 0) {
			await LanguageService.translation.extension
				.update(
					{
						input: translationsUpdate.map(({ diff, ...rest }) => ({
							...rest,
							content: stringToJson(rest?.content),
						})),
					},
					{ token: accessToken },
				)
				.catch((err) => {
					console.log('LanguageService.translation.update', err);
				});

			enqueueSnackbar(
				`${translationsUpdate.length} translations has been updated`,
				{ variant: 'success' },
			);
		}

		if (removedRows.length > 0) {
			await LanguageService.translation.extension.deleteKeys(
				{
					input: removedRows.map(({ id }) => id),
				},
				{ token: accessToken },
			);

			enqueueSnackbar(
				`${removedRows.length} translation keys has deleted`,
				{ variant: 'success' },
			);
		}

		if (removedRows.length === 0 && translationsUpdate.length === 0) {
			enqueueSnackbar('There was nothing to save ??', {
				variant: 'warning',
			});
		}

		reFetch();
	}, [rows, originalRows, accessToken, settings, enqueueSnackbar, reFetch]);

	const columns = useMemo(() => {
		return [
			{
				field: 'key',
				headerName: 'Translation Key',
				flex: 1,
				editable: true,
			},
			{
				field: 'sv',
				headerName: 'Swedish',
				editable: true,
				flex: 1,
				type: 'string',
				valueFormatter: defaultValueFormatter,
				renderCell: defaultRenderCell,
				renderEditCell: defaultRenderEditCell,
			},
			{
				field: 'en',
				headerName: 'English',
				editable: true,
				flex: 1,
				type: 'string',
				valueFormatter: defaultValueFormatter,
				renderCell: defaultRenderCell,
				renderEditCell: defaultRenderEditCell,
			},
			{
				field: 'da',
				headerName: 'Danish',
				editable: true,
				flex: 1,
				type: 'string',
				valueFormatter: defaultValueFormatter,
				renderCell: defaultRenderCell,
				renderEditCell: defaultRenderEditCell,
			},
			{
				field: 'no',
				headerName: 'Norwegian',
				editable: true,
				flex: 1,
				type: 'string',
				valueFormatter: defaultValueFormatter,
				renderCell: defaultRenderCell,
				renderEditCell: defaultRenderEditCell,
			},
			{
				field: 'fi',
				headerName: 'Finish',
				editable: true,
				flex: 1,
				type: 'string',
				valueFormatter: defaultValueFormatter,
				renderCell: defaultRenderCell,
				renderEditCell: defaultRenderEditCell,
			},
			{
				field: 'pt',
				headerName: 'Portuguese',
				editable: true,
				flex: 1,
				type: 'string',
				valueFormatter: defaultValueFormatter,
				renderCell: defaultRenderCell,
				renderEditCell: defaultRenderEditCell,
			},
			{
				field: 'fr',
				headerName: 'French',
				editable: true,
				flex: 1,
				type: 'string',
				valueFormatter: defaultValueFormatter,
				renderCell: defaultRenderCell,
				renderEditCell: defaultRenderEditCell,
			},
			{
				field: 'isNew',
				headerName: 'isNew',
				flex: 1,
				editable: false,
			},
			{
				field: 'actions',
				type: 'actions',
				headerName: 'Actions',
				width: 100,
				cellClassName: 'actions',
				getActions: (row) => {
					if (row?.row?.isNew) {
						return [
							<GridActionsCellItem
								icon={<DeleteIcon />}
								label="Delete"
								className="textPrimary"
								color="inherit"
								onClick={handleDeleteClick(row.id)}
							/>,
						];
					}

					if (row?.row?.isDeleted) {
						return [
							<GridActionsCellItem
								icon={<CancelIcon />}
								label="Cancel"
								className="textPrimary"
								color="error"
								onClick={handleDeleteClick(row.id)}
							/>,
						];
					}

					if (row?.row?.isEdited) {
						return [
							<GridActionsCellItem
								icon={<HistoryIcon />}
								label="Cancel"
								className="textPrimary"
								color="error"
								onClick={handleRevertClick(row.id)}
							/>,
						];
					}

					return [
						<GridActionsCellItem
							icon={<DeleteIcon />}
							label="Delete"
							color="inherit"
							onClick={handleDeleteClick(row.id)}
						/>,
					];
				},
			},
		];
	}, []);

	const isLoading = translations === null || keys === null;
	const [page, setPage] = useState('translations');
	const [language, setLanguage] = useState(null);

	const showSyncPage = useCallback(({ field }) => {
		setPage('sync');
		setLanguage(field);
	}, []);

	const onBack = useCallback(() => {
		setPage('');
		setLanguage(null);
		reFetch();
	}, [reFetch]);

	if (page === 'sync') {
		return (
			<TranslationsSync
				language={language}
				settings={settings}
				onClose={onBack}
			/>
		);
	}

	return (
		<>
			<Paper
				elevation={0}
				sx={{
					flexGrow: 1,
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<DataGrid
					apiRef={apiRef}
					loading={isLoading}
					initialState={initialState}
					showQuickFilter
					slots={{
						loadingOverlay: LinearProgress,
						toolbar: EditToolbar,
						columnMenu: CustomColumnMenu,
					}}
					slotProps={{
						toolbar: {
							setRows,
							setRowModesModel,
							selected,
							partners,
							setSettings,
							settings,
						},
						columnMenu: { onAction: showSyncPage },
					}}
					columns={columns}
					rows={rows}
					rowModesModel={rowModesModel}
					onRowModesModelChange={(newModel) =>
						setRowModesModel(newModel)
					}
					processRowUpdate={handleUpdate}
					disableMultipleColumnsSorting={false}
				/>
			</Paper>
			<Stack spacing={2} direction="row" justifyContent="flex-end" pt={4}>
				<Button
					variant="contained"
					startIcon={<SaveIcon />}
					onClick={handleSave}
				>
					Save
				</Button>
			</Stack>
		</>
	);
};

const TranslationWrapper = () => {
	return (
		<Routes>
			<Route
				path=""
				element={
					<PartnerLayer>
						{({ data: partners }) => (
							<TranslationLayer>
								{({
									settings,
									setSettings,
									data: translations,
									baseData: baseTranslations,
									keys,
									reFetch,
								}) => (
									<Translations
										partners={partners}
										settings={settings}
										setSettings={setSettings}
										translations={translations}
										baseTranslations={baseTranslations}
										keys={keys}
										reFetch={reFetch}
									/>
								)}
							</TranslationLayer>
						)}
					</PartnerLayer>
				}
			/>
		</Routes>
	);
};

export default TranslationWrapper;
