import React, { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';
import { Document, Page } from 'react-pdf';
import { useDispatch, useStore } from 'react-redux';

import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Grid, IconButton, Paper, Typography } from '@mui/material';
import { useSignal, useSignalEffect } from '@preact/signals-react';
import classNames from 'classnames';
import { enqueueSnackbar } from 'notistack';

import { InvoiceService } from '@asteria/backend-utils-services';

import Form from '../../../../components/form';
import { ActionsMenu } from '../../../../components/pdf/actionsMenu';
import { FormContext } from '../../../../components/pdf/context';
import { PDFTextSection } from '../../../../components/pdf/section';
import * as LayoutsStore from '../../../../store/layouts';

import SidePane from './sidepane';
import getInvoicePages from './utils/getInvoicePages';
import transformPages from './utils/transformPages';

const PDFTextDocument = (props) => {
	const {
		className,
		version,
		url,
		height,
		width,
		scale = 2,
		data,
		invoices,
		clients,
		id,
		onSubmit: onFormSubmit,
		onIgnore,
		onRevert,
		onProcessed,
		company,
		showOCR,
		defaultTemplate,
	} = props;

	const { setValue, getValues, resetField, watch, reset } = useFormContext();
	const store = useStore();
	const selectedTemplate = useSignal(defaultTemplate);

	const canvasRef = React.useRef(null);
	const dispatch = useDispatch();

	const [loadClient, setLoadClient] = React.useState(true);

	const [pages, setPages] = React.useState(0);
	const [page, setPage] = React.useState(1);

	const [selectedInvoice, setSelectedInvoice] = React.useState();
	const [layoutData, setLayoutData] = React.useState(null);

	const [hightlight, setHighlight] = React.useState([]);
	const changePage = React.useCallback((page) => {
		setPage(page);
	}, []);

	const onDocumentLoad = React.useCallback((document) => {
		setPages(document.numPages);
	}, []);

	const onNext = React.useCallback(() => {
		changePage(page + 1);
	}, [page, changePage]);

	const onBack = React.useCallback(() => {
		changePage(page - 1);
	}, [page, changePage]);

	useSignalEffect(() => {
		const ID = selectedTemplate.value?._id ?? selectedTemplate.value?.id;
		const template = selectedTemplate.value;

		setValue('version', 2);
		setValue('client', {
			type: 'CUSTOMER',
			meta: { source: company.id },
		});

		setValue('invoice', {
			type: 'invoice',
			clientType: 'CUSTOMER',
			meta: { source: company.id },
		});

		setValue('layout', template?.layout);
		setValue(
			'layout.configuration.columns',
			template?.layout?.configuration?.columns,
		);
		setValue(
			'layout.configuration.invoice.delivery.extra',
			template?.layout?.configuration?.invoice?.delivery?.extra,
		);
		setValue(
			'layout.configuration.invoice.print.summary',
			template?.layout?.configuration?.invoice?.print?.summary,
		);

		setValue(
			'layout.configuration.pages.splitter',
			template?.layout?.configuration?.pages?.splitter,
		);
		setValue(
			'layout.configuration.pages.replace',
			template?.layout?.configuration?.pages?.replace,
		);
		setValue('templateId', ID);
	});

	const onAction = React.useCallback((action, data) => {
		console.log(action, data);
	}, []);

	const onSubmit = React.useCallback(
		async (action, data) => {
			if (action === 'invoice:layout:message') {
				const response = await InvoiceService.invoice.extension
					.addInvoiceLayoutMessage({
						id: data.id,
						message: data.message,
					})
					.catch((err) => ({ ok: false, error: err }));

				if (!response.ok) {
					// eslint-disable-next-line no-console
					console.warn(response.error);
				}

				await data?.refetch();
			}

			if (action === 'invoice:layout:template:fetch') {
				return InvoiceService.invoice.extension
					.invoiceLayoutTemplates({
						pageFilters: { first: 0 },
						fields: `edges { node { _id name createdAt updatedAt layout } }`,
					})
					.then((response) =>
						(response?.edges ?? []).map(({ node }) => node),
					)
					.then((response) => {
						dispatch(LayoutsStore.setTemplates(response));
						return response;
					});
			}

			if (action === 'invoice:layout:template:create') {
				const { layout, ...input } = data;

				await InvoiceService.invoice.extension.addInvoiceLayoutTemplate(
					{
						input: { ...input, layout: layout },
					},
				);

				enqueueSnackbar(`Template created`, { variant: 'success' });

				return onSubmit?.('invoice:layout:template:fetch');
			}

			if (action === 'invoice:layout:template:update') {
				const { id, layout, ...input } = data;

				await InvoiceService.invoice.extension.updateInvoiceLayoutTemplate(
					{
						id: id,
						input: { ...input, layout: layout },
					},
				);

				enqueueSnackbar(`Template updated`, { variant: 'success' });

				return onSubmit?.('invoice:layout:template:fetch');
			}

			if (action === 'invoice:layout:template:delete') {
				const { id } = data;

				await InvoiceService.invoice.extension.deleteInvoiceLayoutTemplate(
					{
						id: id,
					},
				);

				enqueueSnackbar(`Template deleted`, { variant: 'success' });

				return onSubmit?.('invoice:layout:template:fetch');
			}

			if (action === 'invoice:layout:template:save') {
				const templateId =
					selectedTemplate.value?._id ?? selectedTemplate.value?.id;

				const layout = getValues('layout');

				if (layout.fullPage) {
					delete layout.fullPage;
				}

				return onSubmit?.('invoice:layout:template:update', {
					id: templateId,
					layout: layout,
				});
			}
		},
		[dispatch, getValues, selectedTemplate],
	);

	const [selectedField, setSelectedField] = React.useState(null);

	const onSelectText = React.useCallback(
		(item) => {
			// TODO: Change the sidebar to a configuration view, and have a lookup, and then direction stuffy
			// Do a step, like.. Lookup => Condition => Move => Read => Modify
			// setValue(`lookup.${selectedField}.value`, item.text);
			setValue(selectedField, item.text);
		},
		[selectedField, setValue],
	);

	const ctx = React.useMemo(
		() => ({
			layoutData: selectedInvoice
				? { pdfData: { Pages: selectedInvoice?.pages ?? [] } }
				: layoutData,
			setHighlight,
		}),
		[selectedInvoice, layoutData, setHighlight],
	);

	React.useEffect(() => {
		onSubmit?.('invoice:layout:template:fetch');
	}, [onSubmit]);

	const invoiceOperations = watch(
		'layout.operations.invoice.meta.invoiceNumber',
	);

	const [invoicePages, setInvoicePages] = React.useState([]);
	useEffect(() => {
		if (!layoutData) {
			return;
		}

		const invoicePages = getInvoicePages({
			operations: invoiceOperations,
			pages: layoutData?.pdfData?.Pages ?? [],
		});

		setInvoicePages(invoicePages);
	}, [invoiceOperations, layoutData]);

	const pagesSettings = watch('layout.configuration.pages');

	React.useEffect(() => {
		if (!pagesSettings) {
			setLayoutData(data);
			return;
		}

		setLayoutData(transformPages(data, pagesSettings));
	}, [pagesSettings, data]);

	const setInvoice = React.useCallback(
		(e) => {
			const invoice = invoicePages.find(
				({ invoiceNumber }) => invoiceNumber === e.target.value,
			);

			// console.log(invoices);
			const existingInvoice = invoices.find(
				({ invoice: { meta: { invoiceNumber } = {} } = {} }) =>
					invoiceNumber === e.target.value,
			);

			console.log(existingInvoice);

			resetField('rows');
			resetField('client');
			resetField('invoice');

			setValue('version', 2);
			setValue('client', {
				type: 'CUSTOMER',
				meta: { source: company.id },
			});

			setValue('invoice.contact.shipping', {});

			setValue('invoice', {
				type: 'invoice',
				clientType: 'CUSTOMER',
				meta: { source: company.id },
			});

			if (existingInvoice && existingInvoice.id) {
				setValue('invoice.id', existingInvoice.id);
			}

			setSelectedInvoice(invoice);
			setPage(invoice.startPage + 1);
		},
		[invoicePages, setValue, resetField, company.id, invoices],
	);

	useEffect(() => {
		if (invoicePages && invoicePages.length > 0) {
			setInvoice({ target: { value: invoicePages?.[0].invoiceNumber } });
		}
	}, [invoicePages, setInvoice]);

	const clientNumber = watch('client.meta.clientNumber');

	useEffect(() => {
		if (!clientNumber || !loadClient) {
			return;
		}

		const client = clients.find(
			(client) => client?.meta?.clientNumber === clientNumber.trim(),
		);

		if (!client || !client?.contact?.billing?.street) {
			return;
		}

		setValue('client', client);
		setValue('client.meta.source', company.id);
	}, [clientNumber, clients, setValue, company, loadClient]);

	return (
		<FormContext.Provider value={ctx}>
			<ActionsMenu
				onIgnore={onIgnore}
				onRevert={onRevert}
				onFormSubmit={onFormSubmit}
				onProcessed={onProcessed}
				onInvoiceChange={setInvoice}
				companyId={company.id}
				selectedInvoice={selectedInvoice}
				setInvoice={setInvoice}
				invoicePages={invoicePages}
				id={id}
				url={url}
			/>
			<div
				style={{
					flexGrow: 1,
					display: 'flex',
					flexDirection: 'column',
					overflow: 'hidden',
				}}
			>
				<Paper>
					<Grid container spacing={2} justifyContent="space-between">
						<Grid item>
							<IconButton
								variant="outlined"
								onClick={onBack}
								disabled={page <= 1}
							>
								<ArrowBackIos />
							</IconButton>
						</Grid>
						<Grid item>
							<Typography
								variant="h4"
								sx={{ flexGrow: 1 }}
							>{`Page ${page} of ${pages} (v.${version})`}</Typography>
						</Grid>
						<Grid item>
							<IconButton
								variant="outlined"
								onClick={onNext}
								disabled={page >= pages}
							>
								<ArrowForwardIos />
							</IconButton>
						</Grid>
					</Grid>
				</Paper>

				<Paper sx={{ p: 2, mt: 2, flex: 1 }}>
					<div
						style={{
							height: '100%',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<div
							style={{
								overflow: 'hidden',
								display: 'flex',
								flexGrow: 1,
								flexDirection: 'column',
							}}
						>
							<div
								style={{
									overflow: 'auto',
									flex: '1 1 0',
								}}
							>
								<Document
									file={url}
									className={classNames(
										'asteria-component__invoice-document',
										className,
									)}
									onLoadSuccess={onDocumentLoad}
								>
									<div
										className={classNames(
											'asteria-component__invoice-document__page-details',
											'asteria--state-editable',
										)}
									>
										<div
											className="asteria-component__invoice-document__page-wrapper"
											style={{
												width: 1400,
												height: 1980,
											}}
										>
											<Page
												pageNumber={page}
												renderAnnotationLayer={false}
												renderTextLayer={false}
												className="asteria-component__invoice-document__page"
												canvasRef={canvasRef}
												height={height}
												scale={scale}
												width={width}
											/>
											{(
												layoutData?.pdfData?.Pages?.[
													page - 1
												]?.Texts || []
											).map((text) => (
												<PDFTextSection
													key={text.uuid}
													highlight={hightlight.includes(
														text.uuid,
													)}
													item={text}
													page={
														layoutData?.pdfData
															?.Pages?.[page - 1]
													}
													setHighlight={setHighlight}
													onClick={onSelectText}
												/>
											))}
										</div>
									</div>
								</Document>
							</div>
						</div>
					</div>
				</Paper>
			</div>
			<SidePane
				selectedTemplate={selectedTemplate}
				selectedInvoice={selectedInvoice}
				setInvoice={setInvoice}
				invoices={invoices}
				invoicePages={invoicePages}
				loadClient={loadClient}
				setLoadClient={setLoadClient}
				onAction={onAction}
				onSubmit={onSubmit}
				setSelectedField={setSelectedField}
				id={id}
				url={url}
				showOCR={showOCR}
			/>
		</FormContext.Provider>
	);
};

const PDFFormTextDocument = (props) => {
	const { onSubmit, form } = props;

	return (
		<Form
			onSubmit={onSubmit}
			defaultValues={form}
			className="asteria-component__invoice-document__form"
		>
			<PDFTextDocument {...props} />
		</Form>
	);
};

export default PDFFormTextDocument;
